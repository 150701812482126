/*
########   ########    #######    ######    ########   ########    ######     ######         ########      ###     ########   
##     ##  ##     ##  ##     ##  ##    ##   ##     ##  ##         ##    ##   ##    ##        ##     ##    ## ##    ##     ##  
##     ##  ##     ##  ##     ##  ##         ##     ##  ##         ##         ##              ##     ##   ##   ##   ##     ##  
########   ########   ##     ##  ##   ####  ########   ######      ######     ######         ########   ##     ##  ########   
##         ##   ##    ##     ##  ##    ##   ##   ##    ##               ##         ##        ##     ##  #########  ##   ##    
##         ##    ##   ##     ##  ##    ##   ##    ##   ##         ##    ##   ##    ##        ##     ##  ##     ##  ##    ##   
##         ##     ##   #######    ######    ##     ##  ########    ######     ######         ########   ##     ##  ##     ##  
*/

var step = 1;
var switchStep = function() {
    $('.step-slider .step-item:eq('+(step-1)+')').show();
	$("body,html").animate({
        scrollTop: 0
    }, 400, function() {
		$('.step-slider').attr('class','step-slider').addClass('step-'+step);    	
    	$('.progress-bar .col-etape:eq('+step+')').removeClass('active');
    	$('.progress-bar .col-etape:eq('+(step-1)+')').addClass('active');
    	$('.step-slider .step-item:eq('+step+')').fadeOut();
    });

};

/*
########    #######   ##     ##  ########   ########      ###     ########   ##    ##   
##     ##  ##     ##  ###   ###  ##     ##  ##           ## ##    ##     ##   ##  ##    
##     ##  ##     ##  #### ####  ##     ##  ##          ##   ##   ##     ##    ####     
##     ##  ##     ##  ## ### ##  ########   ######     ##     ##  ##     ##     ##      
##     ##  ##     ##  ##     ##  ##   ##    ##         #########  ##     ##     ##      
##     ##  ##     ##  ##     ##  ##    ##   ##         ##     ##  ##     ##     ##      
########    #######   ##     ##  ##     ##  ########   ##     ##  ########      ##      
*/

$(document).ready(function() {

	/*
	 ######     #######    #######   ##    ##   ####  ########   
	##    ##   ##     ##  ##     ##  ##   ##     ##   ##         
	##         ##     ##  ##     ##  ##  ##      ##   ##         
	##         ##     ##  ##     ##  #####       ##   ######     
	##         ##     ##  ##     ##  ##  ##      ##   ##         
	##    ##   ##     ##  ##     ##  ##   ##     ##   ##         
	 ######     #######    #######   ##    ##   ####  ########   
	*/
	$.cookieBar({
		message: "Nous diffusons des cookies afin d'analyser le trafic sur ce site. Les informations concernant l'utilisation que vous faites de notre site nous sont transmises dans cette optique.",
		acceptText: "J'accepte",
		declineButton: true,
		declineText: 'Je refuse',
		expireDays: 30,
		fixed: true,
		zindex:10000
	});

	if(jQuery.cookieBar('cookies')){
		//Google Analytics or other code here
	}

	FastClick.attach(document.body);

	$('[data-link]').click(function() {
		window.location.href = $(this).attr('data-link');
	}).find('a').click(function(e) {
		e.stopPropagation();
	});

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	
	/*
	 ######    ##         ####  ########   ########   ########   
	##    ##   ##          ##   ##     ##  ##         ##     ##  
	##         ##          ##   ##     ##  ##         ##     ##  
	 ######    ##          ##   ##     ##  ######     ########   
	      ##   ##          ##   ##     ##  ##         ##   ##    
	##    ##   ##          ##   ##     ##  ##         ##    ##   
	 ######    ########   ####  ########   ########   ##     ##  
	*/

	$('.next').click(function(e) {
		e.preventDefault();
		if($("#fAvis").validationEngine('validate')){	
			step++;
			switchStep();
		}
	});

	$('.previous').click(function(e) {
		e.preventDefault();
		if(step > 1) {	
			step--;
			switchStep();
		}
	});

	/*
	 ######    ########   ########    ######         ########    #######   ########   ##     ##  
	##    ##   ##     ##  ##         ##    ##        ##         ##     ##  ##     ##  ###   ###  
	##         ##     ##  ##         ##              ##         ##     ##  ##     ##  #### ####  
	 ######    ########   ######     ##              ######     ##     ##  ########   ## ### ##  
	      ##   ##         ##         ##              ##         ##     ##  ##   ##    ##     ##  
	##    ##   ##         ##         ##    ##        ##         ##     ##  ##    ##   ##     ##  
	 ######    ##         ########    ######         ##          #######   ##     ##  ##     ##  
	*/

	$("input[name='Q1B']").click(function() {
		$('#q1b-autre-complement').val('');
		$('#q1b-autre-complement').hide();
	});
	$('#q1b-autre').click(function() {
		$('#q1b-autre-complement').show();
	});


	/*
	 ######     #######   ##    ##   ########      ###      ######    ########   
	##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
	##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
	##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
	##         ##     ##  ##  ####      ##      #########  ##            ##      
	##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
	 ######     #######   ##    ##      ##      ##     ##   ######       ##      
	*/

	$("form").validationEngine({ 
		scroll: false,
		showPrompts: false,
		onFieldFailure: function(_input){
			_input.closest('.error-container').addClass('error');
		},
		onFieldSuccess: function(_input){
			_input.closest('.error-container').removeClass('error');
		}
	});

	$("#fAvis").submit(function(){
		if($("#fAvis").validationEngine('validate')){
			var data          = $(this).serialize() + '&act=envoi';
			var url           = $(this).attr('action');
			var div           = $(this).find("button").prop("disabled",true).html('Envoi en cours...');

			$.ajax({
				type    : "POST",
				data    : data,
				url     : url,
				success : function(retour) {
					try {
						var data = JSON.parse(retour);
						div.html(data.message);
						$(".thanks").show();
						if(data.note < 8) $("#tripadvisor").hide();
					} catch(ex) {
						console.log("Erreur inopinée");
					}
				}
			});
		}
		return false;
	});
});
